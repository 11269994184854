
import React, { useState } from 'react';
import { Button } from 'antd';

import Cluar from '../../common/Cluar';

import './index.less';

function BaseCookies() {
  const [acceptedCookies, setAcceptedCookies] = useState(sessionStorage.getItem('cookies-accepted'));
  const onClick = () => {
    sessionStorage.setItem('cookies-accepted', '1');
    setAcceptedCookies('1');
  }
  if (acceptedCookies === '1') {
    return null;
  }
  return (
    <div className="cookies">
      <div className="cookies--popup">
        <div className="cookies--popup__content styles_container">
          <p>
          {Cluar.currentLanguage().locale === 'pt' && <> {Cluar.plainDictionary('cookies')} <a href={`/${Cluar.currentLanguage().locale}/politica-cookies`}>Política de cookies </a> e <a href={`/${Cluar.currentLanguage().locale}/politica-privacidade`}>Politica de privacidade</a>.</>}
          {Cluar.currentLanguage().locale === 'es' && <> {Cluar.plainDictionary('cookies')} <a href={`/${Cluar.currentLanguage().locale}/politico-cookies`}>Politico de cookies </a> e <a href={`/${Cluar.currentLanguage().locale}/política-y-privacidad`}>Política y privacidad</a>.</>}
          {Cluar.currentLanguage().locale === 'fr' && <> {Cluar.plainDictionary('cookies')} <a href={`/${Cluar.currentLanguage().locale}/politico-cookies`}>Politique en matière de cookies </a> e <a href={`/${Cluar.currentLanguage().locale}/politique-et-confidentialite`}>olitique et confidentialite</a>.</>}
        </p>
        <Button type="primary" shape="round" onClick={onClick}>
          {Cluar.plainDictionary('cookies-accept')}
        </Button>
      </div>
    </div>
    </div >
  )
}

export default BaseCookies;