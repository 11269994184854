import React from 'react';
import { Layout, Row, Col } from 'antd';
import { PhoneOutlined, HomeOutlined, GlobalOutlined, MailOutlined } from '@ant-design/icons';
import { FaHome, FaPhone, FaEnvelope, FaYoutube, FaGithub, FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa';
import { RiOpenSourceFill } from 'react-icons/ri';
import Configuration from '../../components/Configuration';

import './index.less';
import Cluar from '../../common/Cluar';

const { Footer } = Layout;

function BaseFooter() {
  return (
    <>
      <a name="footer"></a>
      <Footer>
        <Row className="ant-layout-footer__wrapper" align="middle" gutter={[0, 10]}>
          <Col md={16} xs={24} lg={8}>
            <div className="logo" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease-out-cubic">
              <img alt="logo" src="/images/logo-header.png" />
            </div>
          </Col>
          <Col md={6} xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
            <Row>
              <Col style={{ marginBottom: '0.5rem' }}>{Cluar.plainDictionary('title-footer')}</Col>
            </Row>
            <Row className="row-footer">
              <Col><HomeOutlined /></Col>
              <Col>&nbsp;</Col>
              <Col>
                <address><Configuration parameter="footer-address" multilines /></address>
              </Col>
            </Row>
            <Row className="row-footer">
              <Col>
                <p className='row-footer-email'><MailOutlined /> {Cluar.plainDictionary('footer-coutinho')}</p>
                <p><GlobalOutlined /> {Cluar.plainDictionary('footer-fiduciatel')}</p>
                {Cluar.currentLanguage().locale == 'fr' &&
                  <p><PhoneOutlined  /> {Cluar.plainDictionary('telephone-footer')}</p>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <hr />
            <p className='fiudicatel-2022' style={{ textAlign: 'center' }}>© {new Date().getFullYear()} Fiduciatel</p>
          </Col>
        </Row>
      </Footer>
    </>
  );
}

export default BaseFooter;