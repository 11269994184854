import React from 'react';
import { Row, Col } from 'antd';

import Actions from '../Actions';

import './index.less';

function Content({ section, type, title, content, image, image_title, image_alt, image_max_width, actions }) {
  let layout = null;
  const imageStyle = {};
  if (image_max_width > 0) {
    imageStyle["maxWidth"] = `${image_max_width}px`;
  }
  if (type === 'text') {
    layout = (
      <div className="content__text">
        <div className="text">
          <h1>{title}</h1>
        </div>
      </div>
    );
  } else if (type === 'image-left') {
    layout = (
      <div className="content__image-left">
        <Row>
          <Col md={8}>
            <div className="image">
              <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
            </div>
          </Col>
          <Col md={16}>
            <div className="text">
              <h1>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else if (type === 'image-right') {
    layout = (
      <div className="content__image-right">
        <Row>
          <Col md={16}>
            <div className="text">
              <h1>{title}</h1>
              <div className="text__title-border"></div>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </Col>
          <Col md={8}>
            <div className="image">
              <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
            </div>
          </Col>
        </Row>
      </div>
    );
  } else if (type === 'image-top') {
    layout = (
      <div className="content__image-top">
        <div className="image">
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
        </div>
        <div className="text">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );
  } else if (type === 'image-bottom') {
    layout = (
      <div className="content__image-bottom">
        <div className="text">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
        <div className="image">
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
        </div>
      </div>
    );
  } else if (type === 'politica-privacidade') {
    layout = (
      <div className={`${type}_content styles_container`}>
        <div className={`${type}_content_text`}>
          <h1 className='title'>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );
  } else if (type === 'content-video') {
    layout = (
      <Row className={`${type}_content styles_container`} gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div >
            <div className={`${type}_content_text`}>
              <h1 className='title'>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12} className='iframe-content'>
          <div className='iframe-fiduciatel'>
            <iframe width="560" height="800" src="https://www.youtube.com/embed/-llT1vsokBc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Col>
      </Row>
    );
  } else {
    layout = (
      <div className={`content__${type}`}>
        <div className="image">
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} style={imageStyle} />
        </div>
        <div className="text">
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );
  }
  return (
    <section className="content">
      {layout}
      <Actions {... { section, type, actions }} />
    </section>
  );
}

export default Content;