import React from 'react';
import { Col, Row } from 'antd';

import './index.less';

function Item({ section, type, image, image_title, image_alt, title, content, link }) {
  let layout = null;
  if (type === 'list-of-services') {
    layout = (
      <React.Fragment>
        <Col xs={20} sm={12} md={8} lg={8} xl={8}>
          <div className={`listing__${type}__items`}>
          <div className='square-at-top'></div>

            <ul className={`listing__${type}__items__item`}>
              <li>{title}</li>
            </ul>
          </div>
        </Col>
      </React.Fragment>
    );
  } else if (type === 'OTHER-CUSTOM-TYPE-HERE') {
    layout = (
      <li className={`listing__item__${type}`} style={{ backgroundImage: `url(/images/${section}/${image})` }}>
        <div className={`listing__item__${type}__content`}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </li>
    );
  } else {
    layout = (
      <li className="listing__item">
        <div className={`listing__item`}>
          <h1>{title}</h1>
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} />
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </li>
    );
  }
  return (
    layout
  );
}

export default Item;