import React from 'react';
import { Row, Col } from 'antd';

import Item from './Item';

import './index.less';

function Listing({ section, type, image, image_title, image_alt, title, content, items, sorter }) {
  const children = [];
  for (const item of items) {
    children.push(<Item key={item.uid} {...{ type, ...item }} />);
  }

  let listLayout = (
    <div>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <ul className={`listing__${type}`}>
        {children}
      </ul>
    </div>
  );

  if (type === 'list-of-services') {
    listLayout = (
      <React.Fragment>
        <a name={`listing-${type}-${sorter}`}></a>
        <div className='styles_container'>
          <div className="heading">
            <h1>{title}</h1>
          </div>
          <Row className={`listing__${type}`} gutter={[16, 16]}>
            {children}
          </Row>
        </div>
      </React.Fragment>
    );
  } else if (type === 'OTHER-CUSTOM-TYPE-HERE') {
    listLayout = (
      <Row className={`listing__${type}`}>
        {children}
      </Row>
    );
  }

  return (
    <section className="listing">
      {listLayout}
    </section>
  );
}

export default Listing;