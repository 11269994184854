import React from 'react';
import { Row, Col } from 'antd';

import Actions from '../Actions';
import Cluar from '../../common/Cluar';

import './index.less';

function Banner({ section, type, image, image_title, image_alt, title, content, position, actions, sorter }) {
  let backgroundPositionX = position.x !== "" ? position.x : "50%";
  let backgroundPositionY = position.y !== "" ? position.y : "50%";

  return (
    <>
      <a name={`banner-${type}-${sorter}`}></a>
      <section className="banner" >
        <div className={`banner__${type}`}>
          <div className='background-degrade'></div>
          <div className='styles_container'>
            <Row className={`banner__${type}_wrapper`} justify="center">
              <Col lg={24} sm={24}>
                <div className={`banner__${type}_wrapper-text`}>
                  <h1 className="title" data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease-out-cubic">{title}</h1>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <div className={`banner__${type}_grid`}>
                <Col>
                  <div className='grid-one'>
                    <img src="/images/solda-fibra-fiduciatel.png" />
                  </div>
                </Col>
                <Col>
                  <div className='grid-two'>
                    <img src="/images/image-5.JPG" />
                  </div>
                </Col>
                <Col>
                  <div className='grid-three'>
                    <img src="/images/Fibra-otica-fiduciatel.png" />
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div >
      </section>
    </>
  );
}

export default Banner;